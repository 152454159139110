import { CommandBarButton, IContextualMenuProps } from "@fluentui/react";
import { useConst } from "@fluentui/react-hooks";
import { isExternalOrReadonlyAdmin } from "form/util";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setDialog } from "../../../store/fleetForm";
import { useSelector } from "../../../store/hooks";
import { EActionDialog } from "../FleetForm/constants";

const FleetMassActionsContextMenu = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loadFleetLoadStatus = useSelector(
    (s) => s.fleetForm.loadFleetLoadStatus
  );
  const fleetAppSettings = useSelector((s) => s.fleetForm.fleetAppSettings);

  const userRole = useSelector((s) => s.auth.userRole);

  const isDisabledLoading = loadFleetLoadStatus === "loading";
  const isDisabled = isDisabledLoading || isExternalOrReadonlyAdmin(userRole);

  const items = useMemo(() => {
    let items = [
      {
        key: "index",
        disabled: isDisabled,
        text: t("bfm.fleet.action.index"),
        iconProps: {
          iconName: "Refresh",
        },
        onClick: () => {
          dispatch(
            setDialog({
              type: EActionDialog.Index,

              isOpen: true,
              title: t("bfm.fleet.action.index"),
              icon: "Refresh",
            })
          );
        },
      },
      {
        key: "adjustment",
        disabled: isDisabled,
        text: t("bfm.fleet.action.adjustment"),
        iconProps: {
          iconName: "Edit",
        },
        onClick: () => {
          dispatch(
            setDialog({
              type: EActionDialog.Adjustment,

              isOpen: true,
              title: t("bfm.fleet.action.adjustment"),
              icon: "Edit",
            })
          );
        },
      },
      {
        key: "paymentMethodChange",
        disabled: isDisabled,
        text: t("bfm.fleet.action.paymentMethodChange"),
        iconProps: {
          iconName: "CalendarWorkWeek",
        },
        onClick: () => {
          dispatch(
            setDialog({
              type: EActionDialog.PaymentMethodChange,

              isOpen: true,
              title: t("bfm.fleet.action.paymentMethodChange"),
              icon: "CalendarWorkWeek",
            })
          );
        },
      },
      {
        key: "changeDueDate",
        disabled: isDisabled,
        text: t("bfm.fleet.action.changeDueDate"),
        iconProps: {
          iconName: "TimeEntry",
        },
        onClick: () => {
          dispatch(
            setDialog({
              type: EActionDialog.ChangeDueDate,
              isOpen: true,
              title: t("bfm.fleet.action.changeDueDate"),
              icon: "TimeEntry",
            })
          );
        },
      },
      {
        key: "addPremium",
        disabled: isDisabled,
        text: t("bfm.fleet.action.addPremium"),
        iconProps: {
          iconName: "Add",
        },
        onClick: () => {
          dispatch(
            setDialog({
              type: EActionDialog.AddPremium,
              isOpen: true,
              title: t("bfm.fleet.action.addPremium"),
              icon: "Add",
            })
          );
        },
      },
      {
        key: "removePremium",
        disabled: isDisabled,
        text: t("bfm.fleet.action.removePremium"),
        iconProps: {
          iconName: "Cancel",
        },
        onClick: () => {
          dispatch(
            setDialog({
              type: EActionDialog.RemovePremium,
              isOpen: true,
              title: t("bfm.fleet.action.removePremium"),
              icon: "Cancel",
            })
          );
        },
      },
      {
        key: "continuePremium",
        disabled: isDisabled,
        text: t("bfm.fleet.action.continuePremium"),
        iconProps: {
          iconName: "Forward",
        },
        onClick: () => {
          dispatch(
            setDialog({
              type: EActionDialog.ContinuePremium,
              isOpen: true,
              title: t("bfm.fleet.action.continuePremium"),
              icon: "Forward",
            })
          );
        },
      },
      {
        key: "updatePremium",
        disabled: isDisabled,
        text: t("bfm.fleet.action.updatePremium"),
        iconProps: {
          iconName: "Refresh",
        },
        onClick: () => {
          dispatch(
            setDialog({
              type: EActionDialog.UpdatePremium,
              isOpen: true,
              title: t("bfm.fleet.action.updatePremium"),
              icon: "Refresh",
            })
          );
        },
      },
    ];
    if (!fleetAppSettings?.INCLUDE_GROUP_POLICY) {
      items.push({
        key: "adjustDeductible",
        disabled: isDisabled,
        text: t("bfm.fleet.action.adjustDeductible"),
        iconProps: {
          iconName: "Money",
        },
        onClick: () => {
          dispatch(
            setDialog({
              type: EActionDialog.AdjustDeductible,
              isOpen: true,
              title: t("bfm.fleet.action.adjustDeductible"),
              icon: "Money",
            })
          );
        },
      });
    }
    return items;
  }, [fleetAppSettings, isDisabled, t]);

  const menuProps = useConst<IContextualMenuProps>(() => ({
    shouldFocusOnMount: true,
    items: items,
  }));

  return (
    <CommandBarButton
      disabled={isDisabled}
      iconProps={{
        iconName: "SetAction",
      }}
      text={t("bfm.massActions.label")}
      menuProps={menuProps}
      styles={{
        root: {
          whiteSpace: "nowrap",
          minHeight: 44,
        },
        flexContainer: {
          alignItems: "normal",
        },
      }}
    />
  );
};

export default FleetMassActionsContextMenu;
