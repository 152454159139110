import { Dialog as FDialog, Icon } from "@fluentui/react";

import usePhraseActivationStatus from "hooks/usePhraseActivationStatus";
import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { Flex, HSpace } from "../../../components/styled";
import { setDialog } from "../../../store/fleetForm";
import { useSelector } from "../../../store/hooks";
import AddPremiumFormDialog from "../actionDialogs/AddPremiumFormDialog/AddPremiumFormDialog";
import AdjustDeductibleFormDialog from "../actionDialogs/AdjustDeductibleFormDialog";
import AdjustmentFormDialog from "../actionDialogs/AdjustmentFormDialog";
import ChangeDueDateDialog from "../actionDialogs/ChangeDueDateDialog";
import ChangePaymentMethodFormDialog from "../actionDialogs/ChangePaymentMethodDialog";
import { ExportFleetDialog } from "../actionDialogs/ExportFleetDialog";
import GenerateExcelComparisonReportFormDialog from "../actionDialogs/GenerateExcelComparisonReportFormDialog";
import GenerateExcelCoversheetReportFormDialog from "../actionDialogs/GenerateExcelCoversheetReportFormDialog";
import GenerateFleetLegalReportFormDialog from "../actionDialogs/GenerateFleetLegalReportFormDialog";
import IndexFormDialog from "../actionDialogs/IndexFormDialog";
import RecaluclateProRataAndBookingsFormDialog from "../actionDialogs/RecalculateProRataAndBookingsFormDialog";
import RemovePremiumFormDialog from "../actionDialogs/RemovePremiumFormDialog";
import SynchronizeFleetClientFormDialog from "../actionDialogs/SynchronizeFleetClientDialog";
import UpdatePremiumFormDialog from "../actionDialogs/UpdatePremiumFormDialog";
import { EActionDialog } from "./constants";
import { SettingForm } from "./tabs/DefaultSettings/SettingForm";
import ContinuePremiumFormDialog from "../actionDialogs/ContinuePremiumFormDialog";

export const Dialog = () => {
  const dialog = useSelector((s) => s.fleetForm.dialog);
  const dispatch = useDispatch();
  const phraseActivated = usePhraseActivationStatus().activated;

  if (!dialog.isOpen) return null;

  const close = () => {
    dispatch(setDialog({ type: "", isOpen: false }));
  };

  const Title = (
    <Flex>
      <Icon
        style={{
          color: dialog.color,
        }}
      />
      <HSpace />
      <div>{dialog.title}</div>
    </Flex>
  );

  let dialogContent;

  switch (dialog.type) {
    case EActionDialog.GenerateFleetLegalReport: {
      dialogContent = <GenerateFleetLegalReportFormDialog />;
      break;
    }
    case EActionDialog.RecalculateProRataAndBookings: {
      dialogContent = <RecaluclateProRataAndBookingsFormDialog />;
      break;
    }
    case EActionDialog.GenerateComparisonReport: {
      dialogContent = <GenerateExcelComparisonReportFormDialog />;
      break;
    }
    case EActionDialog.GenerateCoversheetReport: {
      dialogContent = <GenerateExcelCoversheetReportFormDialog />;
      break;
    }
    case EActionDialog.SynchronizeFleetClient: {
      dialogContent = <SynchronizeFleetClientFormDialog />;
      break;
    }
    case EActionDialog.Index: {
      dialogContent = <IndexFormDialog />;
      break;
    }
    case EActionDialog.Adjustment: {
      dialogContent = <AdjustmentFormDialog />;
      break;
    }
    case EActionDialog.PaymentMethodChange: {
      dialogContent = <ChangePaymentMethodFormDialog />;
      break;
    }
    case EActionDialog.ChangeDueDate: {
      dialogContent = <ChangeDueDateDialog />;
      break;
    }
    case EActionDialog.AddPremium: {
      dialogContent = <AddPremiumFormDialog />;
      break;
    }
    case EActionDialog.RemovePremium: {
      dialogContent = <RemovePremiumFormDialog />;
      break;
    }
    case EActionDialog.ContinuePremium: {
      dialogContent = <ContinuePremiumFormDialog />;
      break;
    }
    case EActionDialog.UpdatePremium: {
      dialogContent = <UpdatePremiumFormDialog />;
      break;
    }
    case EActionDialog.AdjustDeductible: {
      dialogContent = <AdjustDeductibleFormDialog />;
      break;
    }
    case EActionDialog.CreateSetting: {
      dialogContent = <SettingForm />;
      break;
    }
    case EActionDialog.EditSetting: {
      dialogContent = <SettingForm />;
      break;
    }
    case EActionDialog.CopySettings: {
      dialogContent = <SettingForm />;
      break;
    }
    case EActionDialog.ExportFleet: {
      dialogContent = <ExportFleetDialog />;
      break;
    }
    case EActionDialog.ReadOnly: {
      dialogContent = <SettingForm />;
      break;
    }
  }
  return (
    <StyledDialog
      hidden={!dialog.isOpen}
      modalProps={{
        isBlocking: phraseActivated ? false : true,
        styles: {
          main: {
            "@media(min-width: 340px)": {
              maxWidth: "1800px",
            },
          },
        },
      }}
      dialogContentProps={{
        title: Title,
        showCloseButton: true,
      }}
      minWidth={
        dialog.type === "createSetting" ||
        dialog.type === "editSetting" ||
        dialog.type === "adjustment" ||
        "updatePremium"
          ? 800
          : dialog.type === "addPremium"
          ? 1200
          : 500
      }
      onDismiss={close}
      // isBlocking
    >
      {dialogContent}
    </StyledDialog>
  );
};

const StyledDialog = styled(FDialog)`
  min-height: 500px;
  .ms-Dialog-inner {
    padding-bottom: 10px;
  }
  .ms-Dialog-title {
    padding-bottom: 10px;
  }
`;
