import { DefaultButton, DialogFooter, PrimaryButton } from "@fluentui/react";
import { toast } from "components/FluentToast";
import ComboBoxField from "components/controls/ComboBoxField";
import DoubleNumberInputField from "components/controls/DoubleNumberInputField";
import NumberInputField from "components/controls/NumberInputField";
import { Formik } from "formik";
import { useCreateFleetInsurerDefaultSetting } from "hooks/data/mutations/useCreateFleetInsurerDefaultSetting";
import { useUpdateFleetInsurerDefaultSetting } from "hooks/data/mutations/useUpdateFleetInsurerDefaultSetting";
import { EActionDialog } from "pages/fleet-form/FleetForm/constants";
import useActionConfig from "pages/fleet-form/hooks/useActionConfg";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useSelector } from "store/hooks";
import styled from "styled-components";
import { IFleetInsurerDefaultSetting, PremiumType } from "types/types";
import { stringToNumber } from "utils/utils";
import * as yup from "yup";
import { EDialogType } from "../constats";
import BranchOfficeField from "./BranchOfficeField";
import GroupPolicyNumberField from "components/controls/GroupPolicyNumberField";
import TextInputField from "components/controls/TextInputField";
import { formatDayMonthValueForPayload } from "pages/vehicle/helpers";
import { loadFleetWithInsurersActions } from "store/fleetForm";
import { useDispatch } from "react-redux";

type Props = {
  onClose: () => void;
  actionType: EDialogType | string;
  defaultSettings: IFleetInsurerDefaultSetting;
};

const formatNumberForPayload = (value) => {
  if (value?.toString().trim() === "") return null;

  const number = Number(value);

  if (!isNaN(number)) return number;

  return null;
};

function InsurerSettingForm({ onClose, actionType, defaultSettings }: Props) {
  const { fleetId } = useParams() as any;
  const dispatch = useDispatch();

  const {
    mutate: onCreateFleetInsurerDefaultSetting,
    isLoading: createSettingInProgress,
  } = useCreateFleetInsurerDefaultSetting();

  const { mutate: onUpdateFleetInsurerDefaultSetting } =
    useUpdateFleetInsurerDefaultSetting();

  const settingItemData = defaultSettings;

  const isEditSetting = actionType === EActionDialog.EditSetting;
  const isCreateSetting = actionType === EActionDialog.CreateSetting;
  const isCopySetting = actionType === EActionDialog.CopySettings;
  const isReadOnly = actionType === EActionDialog.ReadOnly;

  const { t } = useTranslation();
  const fleetAppSettings = useSelector((s) => s.fleetForm.fleetAppSettings);

  const { paymentMeanOptions, paymentMethodOptions, insurerOptions } =
    useActionConfig(fleetAppSettings);

  const mtplGroupPolicyInfo = useSelector(
    (s) => s.fleetForm.fleetWithPremiums.mtplGroupPolicyInfo
  );
  const hullGroupPolicyInfo = useSelector(
    (s) => s.fleetForm.fleetWithPremiums.hullGroupPolicyInfo
  );

  const insurerGroupPolicyNumberOptions = useMemo(() => {
    let retVal = new Set<any>();

    hullGroupPolicyInfo
      ?.filter((policyInfo) => policyInfo.groupPolicyNumber !== null)
      .map((policyInfo) => ({
        label: policyInfo.groupPolicyNumber,
        value: policyInfo.groupPolicyNumber,
      }))
      .forEach((item) => {
        if (!Array.from(retVal).find((it) => it.value === item.value))
          retVal.add(item);
      });

    mtplGroupPolicyInfo
      ?.filter((policyInfo) => policyInfo.groupPolicyNumber !== null)
      .map((policyInfo) => ({
        label: policyInfo.groupPolicyNumber,
        value: policyInfo.groupPolicyNumber,
      }))
      .forEach((item) => {
        if (!Array.from(retVal).find((it) => it.value === item.value))
          retVal.add(item);
      });

    return Array.from(retVal).flat();
  }, [hullGroupPolicyInfo, mtplGroupPolicyInfo]);

  const initialValues = useMemo(() => {
    const showValues = isEditSetting || isCopySetting || isReadOnly;

    const formattedRenewalDay = settingItemData?.renewalDay
      ?.toString()
      .padStart(2, "0");
    const formattedRenewalMonth = settingItemData?.renewalMonth
      ?.toString()
      .padStart(2, "0");

    return {
      insurerPartnerNumber: showValues
        ? insurerOptions.find(
            (insurer) => insurer.value === settingItemData?.insurerPartnerNumber
          )
        : null,
      suspensionDays: showValues
        ? settingItemData?.suspensionDays?.toString()
        : null,
      activationDays: showValues
        ? settingItemData?.activationDays?.toString()
        : null,
      garageRiskDiscountPercentHull: showValues
        ? settingItemData?.garageRiskDiscountPercentHull
            ?.toString()
            .replace(".", ",")
        : null,
      garageRiskDiscountPercentHullBasic: showValues
        ? settingItemData?.garageRiskDiscountPercentHullBasic
            ?.toString()
            .replace(".", ",")
        : null,
      insurerBranchOffice: showValues
        ? settingItemData?.insurerBranchOffice?.toString()
        : null,
      paymentMethod: showValues
        ? paymentMethodOptions.find(
            (paymentMethod) =>
              paymentMethod.value === settingItemData?.paymentMethod
          )
        : null,
      paymentMethodFleetLegal: showValues
        ? paymentMethodOptions.find(
            (paymentMethod) =>
              paymentMethod.value === settingItemData?.paymentMethodFleetLegal
          )
        : null,
      paymentMethodEngineTax: showValues
        ? paymentMethodOptions.find(
            (paymentMethod) =>
              paymentMethod.value === settingItemData?.paymentMethodEngineTax
          )
        : null,
      paymentMean: showValues
        ? paymentMeanOptions.find(
            (paymentMean) => paymentMean.value === settingItemData?.paymentMean
          )
        : null,
      paymentMeanFleetLegal: showValues
        ? paymentMeanOptions.find(
            (paymentMean) =>
              paymentMean.value === settingItemData?.paymentMeanFleetLegal
          )
        : null,

      ...(fleetAppSettings.INCLUDE_GROUP_POLICY && {
        insurerGroupPolicyNumber: showValues
          ? insurerGroupPolicyNumberOptions.find(
              (insurerGroupPolicyNumber: any) =>
                insurerGroupPolicyNumber?.value ===
                settingItemData?.insurerGroupPolicyNumber
            )
          : null,
        renewalDate:
          showValues && formattedRenewalDay && formattedRenewalMonth
            ? `${formattedRenewalDay}/${formattedRenewalMonth}`
            : null,
      }),
    };
  }, [actionType]);

  const validationSchema = useMemo(() => {
    return yup.object().shape({
      insurerPartnerNumber: yup.mixed().required(),
      activationDays: yup.string().nullable(),
      suspensionDays: yup.string().nullable(),
      garageRiskDiscountPercentHull: yup.string().nullable(),
      garageRiskDiscountPercentHullBasic: yup.string().nullable(),
      paymentMethod: yup.mixed(),
      paymentMethodFleetLegal: yup.mixed(),
      paymentMethodEngineTax: yup.mixed(),
      paymentMean: yup.mixed(),
      paymentMeanFleetLegal: yup.mixed(),
    });
  }, []);

  const onSubmit = useCallback(
    async (data) => {
      let dayMonthRenewalDate = null;

      if (data?.renewalDate) {
        dayMonthRenewalDate = formatDayMonthValueForPayload(data?.renewalDate);
      }
      const payload = {
        insurerPartnerNumber: Number(data?.insurerPartnerNumber?.value) || null,

        suspensionDays: formatNumberForPayload(data?.suspensionDays),
        activationDays: formatNumberForPayload(data?.activationDays),
        garageRiskDiscountPercentHull: formatNumberForPayload(
          stringToNumber(data?.garageRiskDiscountPercentHull)
        ),

        garageRiskDiscountPercentHullBasic: formatNumberForPayload(
          stringToNumber(data?.garageRiskDiscountPercentHullBasic)
        ),

        insurerBranchOffice: Number(data?.insurerBranchOffice?.value) || null,

        paymentMethod: Number(data?.paymentMethod?.value) || null,
        paymentMethodFleetLegal:
          Number(data?.paymentMethodFleetLegal?.value) || null,
        paymentMethodEngineTax:
          Number(data?.paymentMethodEngineTax?.value) || null,
        paymentMean: Number(data?.paymentMean?.value) || null,
        paymentMeanFleetLegal:
          Number(data?.paymentMeanFleetLegal?.value) || null,
        ...(fleetAppSettings.INCLUDE_GROUP_POLICY && {
          insurerGroupPolicyNumber: data?.insurerGroupPolicyNumber?.value,
          renewalDay:
            dayMonthRenewalDate?.dayField && dayMonthRenewalDate?.monthField
              ? dayMonthRenewalDate?.dayField
              : null,
          renewalMonth:
            dayMonthRenewalDate?.dayField && dayMonthRenewalDate?.monthField
              ? dayMonthRenewalDate?.monthField
              : null,
        }),
      };

      if (isCreateSetting || isCopySetting) {
        onCreateFleetInsurerDefaultSetting(
          {
            fleetId,
            data: payload,
          },
          {
            onSuccess: () => {
              toast.success(t("bfm.success"));
              onClose();
              dispatch(loadFleetWithInsurersActions.trigger({ fleetId }));
            },
          }
        );
      } else if (isEditSetting) {
        onUpdateFleetInsurerDefaultSetting(
          {
            fleetId,
            fleetInsurerSettingId: settingItemData.fleetInsurerDefaultSettingId,
            data: {
              ...payload,
              rowVersion: settingItemData?.rowVersion,
            },
          },
          {
            onSuccess: () => {
              toast.success(t("bfm.success"));
              onClose();
              dispatch(loadFleetWithInsurersActions.trigger({ fleetId }));
            },
          }
        );
      }
    },
    [fleetId, isCreateSetting, isEditSetting, settingItemData]
  );

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onSubmit}
    >
      {(props) => {
        const { values, handleSubmit, setFieldValue, handleChange, errors } =
          props;
        const hasOneValueSelected = Object.keys(values)
          .filter(
            (field) =>
              field !== "insurerPartnerNumber" &&
              field !== "insurerBranchOffice" &&
              field !== "insurerGroupPolicyNumber"
          )
          .some((field) => {
            return values[field];
          });

        return (
          <>
            <form onSubmit={handleSubmit}>
              <FormContainer>
                <ComboBoxField
                  required
                  disabled={isEditSetting || isReadOnly}
                  errors={errors}
                  name={"insurerPartnerNumber"}
                  label={"bfm.insurer.label"}
                  setFieldValue={setFieldValue}
                  options={insurerOptions || []}
                  defaultValue={initialValues?.insurerPartnerNumber}
                  confidentiality={null}
                />
                {fleetAppSettings?.SUSPENSION_DAYS_INCLUDED && (
                  <NumberInputField
                    setFieldValue={setFieldValue}
                    value={values?.suspensionDays}
                    onChange={handleChange}
                    errors={errors}
                    name={"suspensionDays"}
                    disabled={isReadOnly}
                    //   isNegativeAllowed={true}
                    label={"bfm.suspensionDays"}
                  />
                )}
                {fleetAppSettings?.ACTIVATION_DAYS_INCLUDED && (
                  <NumberInputField
                    setFieldValue={setFieldValue}
                    value={values?.activationDays}
                    onChange={handleChange}
                    errors={errors}
                    name={"activationDays"}
                    disabled={isReadOnly}
                    //   isNegativeAllowed={true}
                    label={"bfm.activationDays"}
                  />
                )}
                {fleetAppSettings?.GARAGE_RISK_DISCOUNT_PERCENT_INCLUDED && (
                  <>
                    <DoubleNumberInputField
                      setFieldValue={setFieldValue}
                      value={values?.garageRiskDiscountPercentHull}
                      onChange={handleChange}
                      errors={errors}
                      name={"garageRiskDiscountPercentHull"}
                      disabled={isReadOnly}
                      //   isNegativeAllowed={true}
                      label={"bfm.garageRiskDiscountPercentHull.label"}
                    />
                    <DoubleNumberInputField
                      setFieldValue={setFieldValue}
                      value={values?.garageRiskDiscountPercentHullBasic}
                      onChange={handleChange}
                      errors={errors}
                      name={"garageRiskDiscountPercentHullBasic"}
                      disabled={isReadOnly}
                      //   isNegativeAllowed={true}
                      label={"bfm.garageRiskDiscountPercentHullBasic.label"}
                    />
                  </>
                )}
                <BranchOfficeField
                  defaultValue={initialValues?.insurerBranchOffice}
                  disabled={isReadOnly}
                />

                {fleetAppSettings?.INCLUDE_GROUP_POLICY ? (
                  <>
                    <GroupPolicyNumberField
                      id="insurerGroupPolicyNumber"
                      setFieldValue={setFieldValue}
                      options={insurerGroupPolicyNumberOptions || []}
                      defaultValue={values?.insurerGroupPolicyNumber}
                      errors={errors}
                      name={"insurerGroupPolicyNumber"}
                      disabled={isReadOnly}
                      label={"bfm.fleet.setting.insurerGroupPolicyNumber.label"}
                      confidentiality={null}
                      isClearable={true}
                    />
                    <TextInputField
                      isDayMonthField
                      onChange={handleChange}
                      value={values?.renewalDate}
                      errors={errors}
                      name={"renewalDate"}
                      disabled={false}
                      label={"bfm.fleetForm.renewalDate.label"}
                    />
                  </>
                ) : null}

                <ComboBoxField
                  disabled={isReadOnly}
                  errors={errors}
                  name={"paymentMethod"}
                  label={"bfm.vehicleForm.paymentMethod.label"}
                  setFieldValue={setFieldValue}
                  options={paymentMethodOptions || []}
                  confidentiality={null}
                  defaultValue={initialValues?.paymentMethod}
                />

                {fleetAppSettings?.VEHICLE_PREMIUM_TYPES.includes(
                  PremiumType.HORSE_POWER
                ) && (
                  <ComboBoxField
                    disabled={isReadOnly}
                    errors={errors}
                    name={"paymentMethodEngineTax"}
                    label={"bfm.vehicleForm.paymentMethodEngineTax.label"}
                    setFieldValue={setFieldValue}
                    options={paymentMethodOptions || []}
                    confidentiality={null}
                    defaultValue={initialValues?.paymentMethodEngineTax}
                  />
                )}
                {fleetAppSettings?.VEHICLE_PREMIUM_TYPES.includes(
                  PremiumType.FLEETLEGAL_PRORATA
                ) && (
                  <ComboBoxField
                    disabled={isReadOnly}
                    errors={errors}
                    name={"paymentMethodFleetLegal"}
                    label={"bfm.vehicleForm.paymentMethodFleetLegal.label"}
                    setFieldValue={setFieldValue}
                    options={paymentMethodOptions || []}
                    confidentiality={null}
                    defaultValue={initialValues?.paymentMethodFleetLegal}
                  />
                )}
                <ComboBoxField
                  disabled={isReadOnly}
                  errors={errors}
                  name={"paymentMean"}
                  label={"bfm.vehicleForm.paymentMean.label"}
                  setFieldValue={setFieldValue}
                  options={paymentMeanOptions || []}
                  confidentiality={null}
                  defaultValue={initialValues?.paymentMean}
                />
                {fleetAppSettings?.VEHICLE_PREMIUM_TYPES.includes(
                  PremiumType.FLEETLEGAL_PRORATA
                ) && (
                  <ComboBoxField
                    disabled={isReadOnly}
                    errors={errors}
                    name={"paymentMeanFleetLegal"}
                    label={"bfm.vehicleForm.paymentMeanFleetLegal.label"}
                    setFieldValue={setFieldValue}
                    options={paymentMeanOptions || []}
                    confidentiality={null}
                    defaultValue={initialValues?.paymentMeanFleetLegal}
                  />
                )}
              </FormContainer>
              <DialogFooter>
                <ActionsWrap>
                  <DefaultButton text={t("greco.cancel")} onClick={onClose} />
                  {!isReadOnly && (
                    <PrimaryButton
                      type="submit"
                      disabled={!hasOneValueSelected || createSettingInProgress}
                      text={t("greco.save")}
                    />
                  )}
                </ActionsWrap>
              </DialogFooter>
            </form>
          </>
        );
      }}
    </Formik>
  );
}

export default InsurerSettingForm;

const FormContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
`;

const ActionsWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;
