import { Dialog, Icon, TooltipHost } from "@fluentui/react";
import { IFieldConfig } from "components/form/types";
import { Form, Formik } from "formik";
import { useAddVehiclePremium } from "hooks/data/mutations/useAddVehiclePremium";
import { useInsertVehiclePremium } from "hooks/data/mutations/useInsertVehiclePremium";
import { useGetFleetInsurerDefaultSettings } from "hooks/data/queries/getFleetInsurerDefaultSettings";
import usePhraseActivationStatus from "hooks/usePhraseActivationStatus";
import moment from "moment";
import { getInsurerBranchOffice } from "pages/fleet-form/FleetForm/tabs/InsurerDefaultSettings/utils";
import useActionConfig from "pages/fleet-form/hooks/useActionConfg";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { useTheme } from "styled-components";
import { IStyledTheme } from "theme/types";
import { removeEmpty } from "utils/utils";
import * as yup from "yup";
import { RefProvider } from "../../../../contexts/RefProvider";
import { useSelector } from "../../../../store/hooks";
import {
  closeDialog,
  setDialogDefaultValueOperation,
  setDialogInsurerSettingsOperation,
  setOperationRetVal,
} from "../../../../store/timeline";
import {
  IFleetInsurerDefaultSetting,
  PremiumType,
  TimelineSegmentType,
} from "../../../../types/types";
import { setYupLocale } from "../../../../utils/setYupLocale";
import { toast } from "../../../FluentToast";
import { FormSpinner } from "../../../FormSpinner";
import { transformForServer } from "../../../form/transformForServer";
import { Flex, HSpace, StyledPrimaryButton } from "../../../styled";
import { AddPremiumDialogContent } from "../AddPremiumDialogContent";
import { addPremiumDialogConfig } from "../addPremiumDialogConfig";
import { validationObjectConfig } from "../addPremiumDialogValidation";
import { transformForForm } from "../transformForForm";

export type TSetDefaultSettingValues = {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  setFieldTouched?: (field: string, value: boolean) => void;
  values: any;
  insurer?: {
    label: string;
    value: number | string;
  };
  noToastMsg?: boolean;
  mustHaveInsurer?: boolean;
  isHullPremium?: boolean;
};

export const AddPremiumDialog = () => {
  const theme = useTheme() as IStyledTheme;
  const { fleetId } = useParams() as any;

  const [didTrySubmit, setDidTrySubmit] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const timelineAppSettings = useSelector(
    (s) => s.timeline.timelineAppSettings
  );
  const { paymentMeanOptions, paymentMethodOptions } =
    useActionConfig(timelineAppSettings);
  const appSettings = useSelector((s) => s.timeline.timelineAppSettings);

  const item = useSelector((s) => s.timeline.dialog.item);
  const premiumTypesTaxonomy = useSelector(
    (s) => s.taxonomy.PremiumType.byCode
  );
  const insurerList = useSelector((s) => s.vehicle.insurerList);

  const config = useMemo(() => {
    if (!appSettings) return;
    if (appSettings?.HAS_GREENCARD_EXPIRY_DATE === false) {
      const filteredConfig = Object.keys(addPremiumDialogConfig).reduce(
        (acc, key) => {
          if (key !== "greenCardExpiryDate") {
            acc[key] = addPremiumDialogConfig[key];
          }
          return acc;
        },
        {} as IFieldConfig
      );
      return filteredConfig;
    }
    return addPremiumDialogConfig;
  }, [appSettings]);

  const insurerOptions = insurerList
    ? insurerList.map((tax) => {
        return {
          value: tax.insurerInternalNumber,
          label: tax.insurerName,
        };
      })
    : [];

  const { data: fleetInsurerDefaultSettings } =
    useGetFleetInsurerDefaultSettings(fleetId);

  const {
    mutate: onInsertVehiclePremium,
    isLoading: insertVehiclePremiumInProgress,
  } = useInsertVehiclePremium();

  const {
    mutate: onAddVehiclePremium,
    isLoading: addVehiclePremiumInProgress,
  } = useAddVehiclePremium();

  const mainMtplInsurerPartnerNumber = useSelector(
    (s) => s.vehicle.fleet.mainMtplInsurerPartnerNumber
  );

  const mainHullInsurerPartnerNumber = useSelector(
    (s) => s.vehicle.fleet.mainHullInsurerPartnerNumber
  );
  const mainFleetLegalInsurerPartnerNumber = useSelector(
    (s) => s.vehicle.fleet.mainFleetLegalInsurerPartnerNumber
  );

  const premiumTypeKeyItem = useSelector(
    (s) => s.timeline.dialog.item.domain.value
  );

  const phraseActivated = usePhraseActivationStatus().activated;
  const date = useSelector((s) => s.timeline.dialog.date);

  const insurerPartnerNumber = useSelector(
    (s) => s.timeline.dialog.item.associatedObject?.insurerPartnerNumber
  );
  const isLoading =
    insertVehiclePremiumInProgress || addVehiclePremiumInProgress;
  const premiumTypeKey = premiumTypeKeyItem;
  const premiumTypeValue = premiumTypesTaxonomy[premiumTypeKey]?.id;

  const isMtplPremiumType = premiumTypeKey === PremiumType.MTPL;
  const isHullPremiumType = premiumTypeKey === PremiumType.HULL;

  const isFleetLegalProRataPremiumType =
    premiumTypeKey === PremiumType.FLEETLEGAL_PRORATA;
  const isFleetLegalClosingDatePremiumType =
    premiumTypeKey === PremiumType.FLEETLEGAL_CLOSINGDATE;

  const vehicle = useSelector((s) => s.vehicle.vehicle);

  const taxonomy = useSelector((s) => s.taxonomy);

  const dueDay = useSelector((s) => s.vehicle.fleet.dueDay);
  const dueMonth = useSelector((s) => s.vehicle.fleet.dueMonth);

  const fleetPolicyNumber = useSelector(
    (s) => s.vehicle.fleet.fleetPolicyNumber
  );

  const fleetLegalPolicyNumber = useSelector(
    (s) => s.vehicle.fleet.fleetLegalPolicyNumber
  );

  const rowVersion = useSelector((s) => s.vehicle.vehicle?.rowVersion);

  const isInsertOperation =
    item?.segmentType === TimelineSegmentType.VOID_BEFORE ||
    item?.segmentType === TimelineSegmentType.VOID_BETWEEN;

  const hasPremiumHistory = vehicle?.vehiclePremiumSegmentHistoryData?.some(
    (item) => item.premiumTypeCode === premiumTypeValue
  );

  const vehicleRegistrationDate = vehicle?.registrationDate;

  const schema = useMemo(() => {
    setYupLocale(t);
    let yupObject = validationObjectConfig(
      premiumTypeKey,
      t,
      vehicle.isTradeLicensePlate,
      isInsertOperation,
      appSettings
    );
    return yup.object(yupObject);
  }, [t, premiumTypeKey, isInsertOperation, vehicle.isTradeLicensePlate]);

  const setDefaultSettingValues = async ({
    setFieldValue,
    values,
    insurer,
    noToastMsg = true,
    mustHaveInsurer = false,
    isHullPremium = true,
    setFieldTouched,
  }: TSetDefaultSettingValues) => {
    // if (!isMtplPremiumType && !isHullBasicType && !isHullPremiumType) return;
    if (mustHaveInsurer && !insurer) return;

    const insurerInternalNumberByPremiumType = (() => {
      if (insurer) {
        return Number(insurer.value);
      }
      if (isMtplPremiumType) {
        return Number(mainMtplInsurerPartnerNumber);
      }
      if (isHullPremiumType) {
        return Number(mainHullInsurerPartnerNumber);
      }
      if (isFleetLegalProRataPremiumType) {
        return Number(mainFleetLegalInsurerPartnerNumber);
      }

      return null;
    })();

    const insurerPartnerNumberByPremiumType = insurerList.find(
      (insurer) =>
        insurer.insurerInternalNumber === insurerInternalNumberByPremiumType
    )?.insurerInternalNumber;

    let selectedFleetInsurerDefaultSettings: IFleetInsurerDefaultSetting[] =
      (() => {
        if (
          (isMtplPremiumType ||
            isHullPremiumType ||
            isFleetLegalProRataPremiumType) &&
          insurerInternalNumberByPremiumType
        ) {
          return fleetInsurerDefaultSettings.filter(
            (setting) =>
              setting.insurerPartnerNumber ===
              insurerInternalNumberByPremiumType
          );
        }
        return [];
      })();
    if (
      values.insurerGroupPolicyNumber &&
      values.insurerGroupPolicyNumber !== null
    ) {
      selectedFleetInsurerDefaultSettings =
        selectedFleetInsurerDefaultSettings.filter(
          (ds) =>
            ds.insurerGroupPolicyNumber === null ||
            ds.insurerGroupPolicyNumber ===
              values.insurerGroupPolicyNumber.value
        );
    } else {
      selectedFleetInsurerDefaultSettings =
        selectedFleetInsurerDefaultSettings.filter(
          (ds) =>
            ds.insurerGroupPolicyNumber === undefined ||
            ds.insurerGroupPolicyNumber === null
        );
    }
    selectedFleetInsurerDefaultSettings.sort((a, b) => {
      if (
        a.insurerGroupPolicyNumber == null &&
        b.insurerGroupPolicyNumber != null
      ) {
        return 1; // a should come after b
      } else if (
        a.insurerGroupPolicyNumber != null &&
        b.insurerGroupPolicyNumber == null
      ) {
        return -1; // a should come before b
      } else {
        return 0; // keep the original order between two non-null or two null values
      }
    });

    const selectedFleetInsurerDefaultSetting =
      selectedFleetInsurerDefaultSettings[0];

    const selectedInsurer = insurerOptions.find(
      (option) => option.value === insurerPartnerNumberByPremiumType
    );

    const insurerBranchOffice = getInsurerBranchOffice({
      formInsurer: insurerPartnerNumberByPremiumType,
      insurerList,
      formBranchOffice: selectedFleetInsurerDefaultSetting?.insurerBranchOffice,
    });
    setFieldValue("insurerPartnerNumber", selectedInsurer);

    if (selectedFleetInsurerDefaultSetting) {
      setFieldValue("insurerBranchOffice", insurerBranchOffice);
    }
    let fieldNames2 = [];

    if (selectedFleetInsurerDefaultSetting) {
      if (
        selectedFleetInsurerDefaultSetting.renewalDay &&
        selectedFleetInsurerDefaultSetting.renewalMonth &&
        values.validFromDate
      ) {
        const validFromDate = moment(values.validFromDate);
        const renewalDay = selectedFleetInsurerDefaultSetting.renewalDay
          .toString()
          .padStart(2, "0");
        const renewalMonth = selectedFleetInsurerDefaultSetting.renewalMonth
          .toString()
          .padStart(2, "0");

        // Construct the expiry date
        const expiryDate = moment(
          `${validFromDate.year() + 1}-${renewalMonth}-${renewalDay}`,
          "YYYY-MM-DD"
        );

        // If the constructed expiry date is before the valid from date, add one more year
        if (expiryDate.isAfter(moment(validFromDate).add(1, "year"))) {
          expiryDate.subtract(1, "year");
        }
        setFieldValue("expiryDate", expiryDate.toDate());
        setFieldTouched("expiryDate", false);
      }

      const paymentMean = paymentMeanOptions.find(
        (item) => item.value === selectedFleetInsurerDefaultSetting?.paymentMean
      );

      const paymentMethod = paymentMethodOptions.find(
        (item) =>
          item.value === selectedFleetInsurerDefaultSetting?.paymentMethod
      );

      const paymentMeanFleetLegal = paymentMeanOptions.find(
        (item) =>
          item.value ===
          selectedFleetInsurerDefaultSetting?.paymentMeanFleetLegal
      );

      const paymentMethodFleetLegal = paymentMethodOptions.find(
        (item) =>
          item.value ===
          selectedFleetInsurerDefaultSetting?.paymentMethodFleetLegal
      );

      const paymentMethodEngineTax = paymentMethodOptions.find(
        (item) =>
          item.value ===
          selectedFleetInsurerDefaultSetting?.paymentMethodEngineTax
      );

      if (isFleetLegalProRataPremiumType) {
        if (paymentMeanFleetLegal) {
          setFieldValue("paymentMean", paymentMeanFleetLegal);
        } else {
          setFieldValue("paymentMean", paymentMean);
        }
        if (paymentMethodFleetLegal) {
          setFieldValue("paymentMethod", paymentMethodFleetLegal);
        } else {
          setFieldValue("paymentMethod", paymentMethod);
        }
      } else {
        if (paymentMean !== undefined) {
          setFieldValue("paymentMean", paymentMean);
        }
        if (paymentMethod !== undefined) {
          setFieldValue("paymentMethod", paymentMethod);
        }
      }

      setFieldTouched("motorTaxPaymentMethod", false);

      if (paymentMethodEngineTax) {
        setFieldValue("motorTaxPaymentMethod", paymentMethodEngineTax);
      } else {
        setFieldValue("motorTaxPaymentMethod", paymentMethod);
      }

      if (selectedFleetInsurerDefaultSetting?.activationDays?.toString()) {
        setFieldValue(
          "activationDays",
          selectedFleetInsurerDefaultSetting?.activationDays
        );
      } else {
        fieldNames2.push("activationDays");
      }

      if (selectedFleetInsurerDefaultSetting?.suspensionDays?.toString()) {
        setFieldValue(
          "suspensionDays",
          selectedFleetInsurerDefaultSetting?.suspensionDays
        );
      } else {
        fieldNames2.push("suspensionDays");
      }

      if (isHullPremium) {
        if (
          selectedFleetInsurerDefaultSetting?.garageRiskDiscountPercentHull?.toString()
        ) {
          setFieldValue(
            "garageRiskDiscountPercent",
            selectedFleetInsurerDefaultSetting?.garageRiskDiscountPercentHull
              ?.toString()
              .replace(".", ",")
          );
        } else {
          fieldNames2.push("garageRiskDiscountPercent");
        }
      } else {
        if (
          selectedFleetInsurerDefaultSetting?.garageRiskDiscountPercentHullBasic?.toString()
        ) {
          setFieldValue(
            "garageRiskDiscountPercent",
            selectedFleetInsurerDefaultSetting?.garageRiskDiscountPercentHullBasic
              ?.toString()
              .replace(".", ",")
          );
        } else {
          fieldNames2.push("garageRiskDiscountPercent");
        }
      }
    } else {
      fieldNames2.push("suspensionDays");
      fieldNames2.push("activationDays");
      fieldNames2.push("garageRiskDiscountPercent");
    }

    let fieldNames1 = ["annualGrossPremium", "deductible", "calculation"];

    dispatch(
      setDialogDefaultValueOperation({
        fieldNames: fieldNames1,
        formValues: values,
        noMessage: noToastMsg,
      })
    );

    fieldNames2.push("suspensionDaysLimit");
    fieldNames2.push("motorTaxSuspensionDaysLimit");

    fieldNames2.push("annualNetPremium");

    dispatch(
      setDialogInsurerSettingsOperation({
        fieldNames: fieldNames2,
        formValues: values,
        noMessage: noToastMsg,
      })
    );
  };

  const onSubmit = (data) => {
    let result = transformForServer({
      obj: data,
      config: config,
    }) as any;
    result = removeEmpty(result);
    let premiumTypeKeyFinal = premiumTypeKey;

    const isFleetLegalClosingDate =
      data?.premiumType?.value ===
      taxonomy.PremiumType.byCode[PremiumType.FLEETLEGAL_CLOSINGDATE]?.id;

    if (
      premiumTypeKeyFinal === PremiumType.HULL &&
      data.hullIsBasic.value === "true"
    ) {
      premiumTypeKeyFinal = PremiumType.HULL_BASIC;
    }

    let objectToSend = {
      vehicleId: vehicle.vehicleId as any,
      fleetId: vehicle.fleetId as any,
      body: {
        ...result,
        premiumType:
          data?.premiumType?.value ||
          taxonomy.PremiumType.byCode[`${premiumTypeKeyFinal}`]?.id,
        hullPremiumRelatedInfo: {
          hullProduct: result.hullProduct,
          hullDeductiblePercent: result.hullDeductiblePercent,
          hullDeductibleMaxAmount: result.hullDeductibleMaxAmount,
          hullDeductibleMinAmount: result.hullDeductibleMinAmount,
          hullDeductibleComment: result.hullDeductibleComment,
          gap: result.gap,
          replacementValue: result.replacementValue,
          grossNegligence: result.grossNegligence,
          electroPlusPackage: result.electroPlusPackage,
        },
      },
    };
    delete objectToSend.body.hullProduct;
    delete objectToSend.body.hullDeductiblePercent;
    delete objectToSend.body.hullDeductibleMaxAmount;
    delete objectToSend.body.hullDeductibleMinAmount;
    delete objectToSend.body.hullDeductibleComment;
    delete objectToSend.body.gap;
    delete objectToSend.body.replacementValue;
    delete objectToSend.body.grossNegligence;
    delete objectToSend.body.electroPlusPackage;

    if (objectToSend.body?.isMotorTaxIncluded === true) {
      objectToSend.body = {
        ...objectToSend.body,
        motorTaxExclusionReason: null,
      };
    }
    if (
      premiumTypeKey !== PremiumType.HULL &&
      premiumTypeKey !== PremiumType.HULL_BASIC
    ) {
      delete objectToSend.body.hullPremiumRelatedInfo;
    }

    if (isFleetLegalClosingDate) {
      objectToSend.body.isFirstForFleetLegalClosingDate =
        data.isFirstForFleetLegalClosingDate;
    }

    isInsertOperation
      ? onInsertVehiclePremium(
          {
            rowVersion,
            params: objectToSend,
          },
          {
            onSuccess: (res) => {
              dispatch(setOperationRetVal(res?.data));
              toast.success(t("bfm.success"));
              dispatch(closeDialog());
            },
          }
        )
      : onAddVehiclePremium(
          {
            rowVersion,
            params: objectToSend,
          },
          {
            onSuccess: (res) => {
              dispatch(setOperationRetVal(res?.data));
              toast.success(t("bfm.success"));
              dispatch(closeDialog());
            },
          }
        );
  };

  const Title = (
    <Flex>
      <Icon
        iconName="CircleAddition"
        style={{
          color: theme.palette.green,
        }}
      />
      <HSpace />
      <div>
        {isInsertOperation ? t("bfm.insertPremium") : t("bfm.addPremium")}{" "}
        {t(`${premiumTypeKey}`)}
      </div>
    </Flex>
  );

  const validFromRaw = hasPremiumHistory ? date : vehicleRegistrationDate;
  const validFromDate = moment(validFromRaw).toDate();
  const momentDate = moment(date).toDate();

  const initialValues = useMemo(() => {
    const insurerInternalNumberByPremiumType = (() => {
      if (isMtplPremiumType) {
        return Number(mainMtplInsurerPartnerNumber);
      }
      if (isHullPremiumType) {
        return Number(mainHullInsurerPartnerNumber);
      }
      if (isFleetLegalProRataPremiumType) {
        return Number(mainFleetLegalInsurerPartnerNumber);
      }

      return null;
    })();

    const insurerPartnerNumberByPremiumType = insurerList.find(
      (insurer) =>
        insurer.insurerInternalNumber === insurerInternalNumberByPremiumType
    )?.insurerInternalNumber;

    const expiryDate = new Date(
      validFromDate.getFullYear() + 1,
      Number(dueMonth) - 1, // if month is 1 that is february
      Number(dueDay)
    );
    let obj: any = {
      insurerPartnerNumber: insurerPartnerNumber,
      validFromDate,
      expiryDate,
      validToDate: item.endDate,
      annualGrossPremium: 0,
    };

    if (
      isMtplPremiumType ||
      isHullPremiumType ||
      isFleetLegalProRataPremiumType
    ) {
      obj = {
        ...obj,
        insurerPartnerNumber: insurerPartnerNumberByPremiumType,
      };
    }

    if (isHullPremiumType) {
      obj = {
        ...obj,
        hullIsBasic: false,
      };
    }

    if (
      premiumTypeKey === PremiumType.MTPL ||
      premiumTypeKey === PremiumType.HULL ||
      premiumTypeKey === PremiumType.HULL_BASIC ||
      //debugger
      isFleetLegalProRataPremiumType ||
      isFleetLegalClosingDatePremiumType
      //debugger
    ) {
      obj = {
        ...obj,
        insurerPolicyNumber: isFleetLegalProRataPremiumType
          ? fleetLegalPolicyNumber
          : fleetPolicyNumber,
        dueDate:
          ((dueDay + "").length === 1 ? "0" + dueDay : dueDay) +
          "" +
          ((dueMonth + "").length === 1 ? "0" + dueMonth : dueMonth),
      };
    }

    if (appSettings?.INCLUDE_GROUP_POLICY) {
      obj = {
        ...obj,
        groupPolicyNumber: null,
      };
    }
    if (isFleetLegalProRataPremiumType) {
      obj = {
        ...obj,
        suspensionDaysLimit: "-1",
      };
    }

    if (isFleetLegalClosingDatePremiumType) {
      obj = {
        ...obj,
        isFirstForFleetLegalClosingDate: null,
      };
    }
    let initObj = transformForForm({
      obj: obj,
      config: config,
      t,
      taxonomy,
      insurerList,
      possibleMainVehicles: null,
    });
    initObj = {
      ...initObj,

      validToDate: item.endDate,
    };
    if (
      premiumTypeKey === PremiumType.MTPL &&
      appSettings?.VEHICLE_PREMIUM_DOMAINS.includes(PremiumType.HORSE_POWER)
    ) {
      initObj = {
        ...initObj,
        isMotorTaxIncluded: true,
        motorTaxSuspensionDaysLimit: "45",
      };
    }
    const suspensionDaysIncluded = appSettings?.SUSPENSION_DAYS_INCLUDED;
    const suspensionDaysDefault = appSettings?.SUSPENSION_DAYS_DEFAULT;
    const activationDaysIncluded = appSettings?.ACTIVATION_DAYS_INCLUDED;
    const activationDaysDefault = appSettings?.ACTIVATION_DAYS_DEFAULT;
    const suspensionDaysLimitIncluded =
      appSettings?.SUSPENSION_DAYS_LIMIT_INCLUDED;
    const suspensionDaysLimitDefault =
      appSettings?.SUSPENSION_DAYS_LIMIT_DEFAULT;
    const garageRiskDiscountPercentIncluded =
      appSettings?.GARAGE_RISK_DISCOUNT_PERCENT_INCLUDED;
    const garageRiskDiscountPercentDefault =
      appSettings?.GARAGE_RISK_DISCOUNT_PERCENT_DEFAULT;

    if (!suspensionDaysIncluded) {
      initObj = {
        ...initObj,
        suspensionDays: suspensionDaysDefault + "",
      };
    }
    if (!activationDaysIncluded) {
      initObj = {
        ...initObj,
        activationDays: activationDaysDefault + "",
      };
    }
    if (!suspensionDaysLimitIncluded) {
      initObj = {
        ...initObj,
        suspensionDaysLimit: suspensionDaysLimitDefault,
      };
    }
    if (!garageRiskDiscountPercentIncluded) {
      initObj = {
        ...initObj,
        garageRiskDiscountPercent: garageRiskDiscountPercentDefault + "",
      };
    }
    initObj = {
      ...initObj,
      policyStatus: "",
    };

    // if (
    //   AppSettings.getInstance()
    //     .getSettings()
    //     .VEHICLE_TIMELINE_TYPES.includes(TimelineType.VEHICLE_POLICY_STATUSES)
    // ) {
    //   initObj = {
    //     ...initObj,
    //     policyStatus: {
    //       label: t("VehiclePolicyStatusCode.PENDING"),
    //       value:
    //         taxonomy.VehiclePolicyStatusCode.byCode[
    //           "VehiclePolicyStatusCode.PENDING"
    //         ].id,
    //     },
    //   };
    // }

    return initObj;
  }, [
    t,
    premiumTypeKey,
    taxonomy,
    insurerList,
    insurerPartnerNumber,
    item,
    momentDate,
    dueDay,
    dueMonth,
    vehicleRegistrationDate,
    hasPremiumHistory,
    mainMtplInsurerPartnerNumber,
  ]);

  return (
    <Dialog
      hidden={false}
      dialogContentProps={{
        title: Title,
        showCloseButton: true,
      }}
      minWidth={
        (premiumTypeKey === PremiumType.MTPL &&
          appSettings?.VEHICLE_PREMIUM_DOMAINS.includes(
            PremiumType.HORSE_POWER
          )) ||
        premiumTypeKey === PremiumType.HULL
          ? 1050
          : 700
      }
      onDismiss={() => {
        dispatch(closeDialog());
      }}
      modalProps={{
        isBlocking: phraseActivated ? false : true,
      }}
    >
      <RefProvider>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          enableReinitialize
          validateOnChange={didTrySubmit}
          validateOnBlur={didTrySubmit}
          validationSchema={schema}
        >
          {({ submitForm, values, setFieldValue, setFieldTouched }) => {
            return (
              <Form>
                <AddPremiumDialogContent
                  item={item}
                  premiumTypeKey={premiumTypeKey}
                  setDefaultSettingValues={setDefaultSettingValues}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <TooltipHost content={t("bfm.fillInAllDefaults.label")}>
                    <StyledPrimaryButton
                      text={t("bfm.defaultSettings.label")}
                      onClick={() => {
                        const isHullPremium =
                          values["hullIsBasic"]?.value === "full" ||
                          values["hullIsBasic"]?.value === "false";

                        setDefaultSettingValues({
                          setFieldValue,
                          values,
                          insurer: values["insurerPartnerNumber"],
                          mustHaveInsurer: true,
                          noToastMsg: false,
                          isHullPremium,
                          setFieldTouched,
                        });
                      }}
                      iconProps={{
                        iconName: "Shield",
                      }}
                      disabled={
                        isLoading ||
                        !values["insurerPartnerNumber"] ||
                        !values["validFromDate"]
                      }
                    />
                  </TooltipHost>

                  <StyledPrimaryButton
                    text={t("greco.save")}
                    onClick={() => {
                      setDidTrySubmit(true);
                      submitForm();
                    }}
                    iconProps={{
                      iconName: "Save",
                    }}
                    disabled={isLoading}
                  />
                </div>
                {isLoading && <FormSpinner />}
              </Form>
            );
          }}
        </Formik>
      </RefProvider>
    </Dialog>
  );
};
