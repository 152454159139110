import { Icon, Persona, PersonaSize, TooltipHost } from "@fluentui/react";
import { Flex } from "components/styled";
import { parseDayMonthFromNumber } from "pages/vehicle/helpers";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "store/hooks";
import styled, { useTheme } from "styled-components";
import { IStyledTheme } from "theme/types";
import { formatNumber } from "utils/number";
import {
  formatDateString,
  formatDateStringTimeOnly,
} from "../../../../utils/utils";

export const History = ({ historyRef }) => {
  const theme = useTheme() as IStyledTheme;
  const fleet = useSelector((s) => s.fleetForm.fleetWithPremiums);
  const insurerList = useSelector((s) => s.fleetForm.insurerList);

  const { t } = useTranslation();
  const taxonomy = useSelector((s) => s.taxonomy);

  return (
    <div
      ref={historyRef}
      id="history-tab"
      style={{
        marginTop: "100px",
      }}
    >
      <Table>
        <thead>
          <tr>
            <th>{t("bfm.user")}</th>
            <th>{t("bfm.fleet.history.header.action")}</th>
            <th>{t("bfm.fleet.premiumType.label")}</th>
            <th>{t("bfm.fleet.history.insurer")}</th>
            <th>{t("bfm.date.label")}</th>
            <th>{t("bfm.fleet.history.header.value")}</th>
            <th>{t("bfm.criteria")}</th>

            <th>
              <Icon iconName="Attach" />
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {fleet?.fleetActionHistoryData?.map((item, index) => {
            const premiumTypeTaxonomyCode =
              taxonomy.PremiumType.byId[item.premiumTypeCode]?.code;
            const insurerName = insurerList.find(
              (insurer) =>
                insurer?.insurerInternalNumber === item.insurerPartnerNumber
            )?.insurerName;
            const fleetActionReasonTaxonomyCode =
              taxonomy.ReasonForFleetModification.byId[item.fleetActionReason]
                ?.code;

            let fleetActionReason = t(`bfm.fleet.history.created`);
            if (fleetActionReasonTaxonomyCode?.includes("CORRECTING")) {
              fleetActionReason = t(`bfm.fleet.history.fleet_updated`);
            } else if (fleetActionReasonTaxonomyCode?.includes("CONTINUING")) {
              fleetActionReason = t(`bfm.fleet.history.continued`);
            } else if (
              fleetActionReasonTaxonomyCode?.includes(
                "ADJUSTING_HULL_DEDUCTIBLE"
              )
            ) {
              fleetActionReason = t(`bfm.fleet.history.adjustedHullDeductible`);
            } else if (fleetActionReasonTaxonomyCode?.includes("ADJUSTING")) {
              fleetActionReason = t(`bfm.fleet.history.adjusted`);
            } else if (fleetActionReasonTaxonomyCode?.includes("INDEXING")) {
              fleetActionReason = t(`bfm.fleet.history.indexed`);
            } else if (fleetActionReasonTaxonomyCode?.includes("ADDING")) {
              fleetActionReason = t(`bfm.fleet.history.added`);
            } else if (fleetActionReasonTaxonomyCode?.includes("UPDATING")) {
              fleetActionReason = t(`bfm.fleet.history.updated`);
            } else if (fleetActionReasonTaxonomyCode?.includes("CANCELING")) {
              fleetActionReason = t(`bfm.fleet.history.canceled`);
            } else if (fleetActionReasonTaxonomyCode?.includes("PAYMENT")) {
              fleetActionReason = t(`bfm.fleet.history.paymentMethodChanged`);
            } else if (
              fleetActionReasonTaxonomyCode?.includes("CHANGING_DUE_DATE")
            ) {
              fleetActionReason = t(`bfm.fleet.history.dueDate`);
            }

            const s = {
              ...item,
              settingCriteria: item.fleetActionCriteria,
            };

            const carUsageCode = s.settingCriteria?.carUsageCodes
              ?.map((e) => t(taxonomy.VehicleUsageType.byId[e]?.code))
              .join(", ");

            // const vehicleMainGroupTaxonomyCode =
            //   taxonomy.VehicleMainGroup.byId[s.vehicleMainGroup]?.code;
            const vehicleTypeTaxonomyCode = s.settingCriteria?.vehicleTypeCodes
              ?.map((e) => t(taxonomy.VehicleTypeLegal.byId[e]?.code))
              .join(", ");
            const maxGrossWeightKgRange =
              s.settingCriteria?.maxGrossWeightKgRangeCodes
                ?.map((e) => t(taxonomy.MaxGrossWeightKgRange.byId[e]?.code))
                .join(", ");

            const weightCapacityTaxonomyCode =
              s.settingCriteria?.weightCapacityKgRangeCodes
                ?.map((e) => t(taxonomy.WeightCapacityKgRange.byId[e]?.code))
                .join(", ");

            const powerKwFrom = s?.settingCriteria?.powerKws
              ? s?.settingCriteria?.powerKws[0].from
              : null;
            const powerKwTo = s?.settingCriteria?.powerKws
              ? s?.settingCriteria?.powerKws[0].to
              : null;

            const emptyWeightKgFrom = s?.settingCriteria?.emptyWeightKgs
              ? s?.settingCriteria?.emptyWeightKgs[0].from
              : null;
            const emptyWeightKgTo = s?.settingCriteria?.emptyWeightKgs
              ? s?.settingCriteria?.emptyWeightKgs[0].to
              : null;

            const cubicCapacityCcmRange =
              s.settingCriteria?.cubicCapacityCcmRangeCodes
                ?.map((e) => t(taxonomy.CubicCapacityCcmRangeHg1.byId[e]?.code))
                .join(", ");

            const numberOfSeatsMoped =
              s.settingCriteria?.numberOfSeatsMopedCodes
                ?.map((e) => t(taxonomy.NumberOfSeatsMoped.byId[e]?.code))
                .join(", ");

            const numberOfSeatsRange =
              s.settingCriteria?.numberOfSeatsRangeCodes
                ?.map((e) => t(taxonomy.NumberOfSeatsRange.byId[e]?.code))
                .join(", ");

            const listPriceTypeCode = s?.settingCriteria?.listPrices
              ? taxonomy.ListPriceType.byId[
                  s?.settingCriteria?.listPrices[0].type
                ]?.code
              : null;

            const listPriceMin = s?.settingCriteria?.listPrices
              ? s?.settingCriteria?.listPrices[0].min
              : null;
            const listPriceMax = s?.settingCriteria?.listPrices
              ? s?.settingCriteria?.listPrices[0].max
              : null;

            const insurerGroupPolicyNumber = item.insurerGroupPolicyNumber;
            const policyStatus = item.policyStatus;

            const policyStatusCode = policyStatus
              ? taxonomy.VehiclePolicyStatusCode.byId[policyStatus]?.code
              : null;

            let value = (() => {
              const fleetActionValue = item.fleetActionValue;
              if (!fleetActionValue) return "";

              if (
                fleetActionReasonTaxonomyCode?.includes("CHANGING_DUE_DATE")
              ) {
                const dayMonthDueDate =
                  parseDayMonthFromNumber(fleetActionValue);
                return `${dayMonthDueDate.day}.${dayMonthDueDate.month}`;
              }

              if (fleetActionReasonTaxonomyCode?.includes("PAYMENT")) {
                return t(
                  taxonomy.PaymentMethod.byId[item.fleetActionValue]?.code
                );
              }

              return formatNumber(item.fleetActionValue);
            })();

            return (
              <tr
                key={item.fleetActionExecutedAt + item.fleetActionReasonComment}
                style={{ borderBottom: "1px solid rgb(237, 235, 233)" }}
              >
                <td>
                  <Flex justify="center">
                    <Persona
                      text={item.fleetActionExecutedByUserName}
                      title={item.fleetActionExecutedByUserName}
                      hidePersonaDetails
                      size={PersonaSize.size32}
                    />
                  </Flex>
                </td>
                <td>
                  <FleetActionCell justify="center">
                    {fleetActionReason}
                  </FleetActionCell>
                </td>
                <td>
                  <PremiumTypeCell justify="center">
                    {!!!(
                      s.settingCriteria?.vehicleTypeCodes &&
                      s.settingCriteria?.vehicleTypeCodes.length > 0
                    ) ? (
                      <TooltipHost content={t("bfm.tradeLicensePlates.label")}>
                        <Icon
                          style={{
                            visibility: item.fleetActionCriteria
                              ?.isTradeLicensePlate
                              ? "visible"
                              : "hidden",
                          }}
                          iconName="Market"
                        />
                        &nbsp;&nbsp;
                      </TooltipHost>
                    ) : null}
                    {t(premiumTypeTaxonomyCode)}
                  </PremiumTypeCell>
                </td>
                <td>
                  <InsurerTypeCell justify="center">
                    {insurerName}
                  </InsurerTypeCell>
                </td>
                <td>
                  <PeriodCell justify="center">
                    {item.fleetActionStartDate
                      ? formatDateString(item.fleetActionStartDate)
                      : ""}{" "}
                  </PeriodCell>
                </td>
                <td>
                  <Cell justify="center">{value}</Cell>
                </td>
                <td>
                  <Cell justify="center">
                    {insurerGroupPolicyNumber ? (
                      <>
                        {t("bfm.fleet.setting.insurerGroupPolicyNumber.label") +
                          ": "}
                        {t(insurerGroupPolicyNumber)}
                        <br />
                      </>
                    ) : null}
                    {policyStatusCode ? (
                      <>
                        {t("bfm.vehicleForm.policyStatus.label") + ": "}
                        {t(policyStatusCode)}
                        <br />
                      </>
                    ) : null}
                    <>
                      {t("bfm.tradeLicensePlates.label") + ": "}
                      {item.fleetActionCriteria?.isTradeLicensePlate
                        ? t("greco.yes")
                        : t("greco.no")}
                      <br />
                    </>
                    {vehicleTypeTaxonomyCode ? (
                      <>
                        {t("bfm.vehicleForm.vehicleTypeCode.label") + ": "}
                        {t(vehicleTypeTaxonomyCode)}
                        <br />
                      </>
                    ) : null}
                    {carUsageCode ? (
                      <>
                        {t("bfm.vehicleForm.carUsageCode.label") + ": "}
                        {t(carUsageCode)}
                        <br />
                      </>
                    ) : null}
                    {listPriceTypeCode ? (
                      <>
                        {t("bfm.vehicleForm.listPriceTypeCode.label") + ": "}
                        {t(listPriceTypeCode)}
                        <br />
                      </>
                    ) : null}
                    {listPriceMin != null ? (
                      <>
                        {t("bfm.listPriceMin.label") + ": "}
                        {listPriceMin}
                        <br />
                      </>
                    ) : null}
                    {listPriceMax != null ? (
                      <>
                        {t("bfm.listPriceMax.label") + ": "}
                        {listPriceMax}
                        <br />
                      </>
                    ) : null}
                    {weightCapacityTaxonomyCode ? (
                      <>
                        {t("bfm.vehicleForm.weightCapacityKg.label") + ": "}
                        {t(weightCapacityTaxonomyCode)}
                        <br />
                      </>
                    ) : null}
                    {maxGrossWeightKgRange ? (
                      <>
                        {t("bfm.fleet.adjustmentDialog.maxGrossWeightKgRange") +
                          ": "}
                        {t(maxGrossWeightKgRange)}
                        <br />
                      </>
                    ) : null}
                    {powerKwFrom ? (
                      <>
                        {t("bfm.vehicleForm.powerKwFrom.label") + ": "}
                        {powerKwFrom}
                        <br />
                      </>
                    ) : null}
                    {powerKwTo ? (
                      <>
                        {t("bfm.vehicleForm.powerKwTo.label") + ": "}
                        {powerKwTo}
                        <br />
                      </>
                    ) : null}
                    {emptyWeightKgFrom ? (
                      <>
                        {t("bfm.vehicleForm.emptyWeightKgFrom.label") + ": "}
                        {emptyWeightKgFrom}
                        <br />
                      </>
                    ) : null}
                    {emptyWeightKgTo ? (
                      <>
                        {t("bfm.vehicleForm.emptyWeightKgTo.label") + ": "}
                        {emptyWeightKgTo}
                        <br />
                      </>
                    ) : null}
                    {cubicCapacityCcmRange ? (
                      <>
                        {t("bfm.vehicleForm.cubicCapacityCcm.label") + ": "}
                        {t(cubicCapacityCcmRange)}
                        <br />
                      </>
                    ) : null}
                    {numberOfSeatsMoped ? (
                      <>
                        {t("bfm.vehicleForm.seats.label") + ": "}
                        {t(numberOfSeatsMoped)}
                        <br />
                      </>
                    ) : null}
                    {numberOfSeatsRange ? (
                      <>
                        {t("bfm.vehicleForm.seats.label") + ": "}
                        {t(numberOfSeatsRange)}
                        <br />
                      </>
                    ) : null}
                  </Cell>
                </td>
                <td>
                  <Cell justify="center">
                    {item.importOrUpdateDocumentPath ? (
                      <>
                        <Icon
                          style={{
                            cursor: "pointer",
                            fontSize: "20px",
                          }}
                          iconName="ExcelDocument"
                          title={item.importOrUpdateDocumentPath}
                          onClick={() =>
                            window.open(item.importOrUpdateDocumentPath)
                          }
                        />
                        &nbsp;
                      </>
                    ) : null}
                    {item.reportDocumentPath ? (
                      <Icon
                        style={{
                          cursor: "pointer",
                          fontSize: "20px",
                        }}
                        iconName="ExcelDocument"
                        title={item.reportDocumentPath}
                        onClick={() => window.open(item.reportDocumentPath)}
                      />
                    ) : null}
                  </Cell>
                </td>
                <td>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <div>
                      <div
                        style={{
                          fontSize: "12px",
                          fontStyle: "italic",
                          color: theme.palette.neutralSecondaryAlt,
                        }}
                      >
                        {formatDateString(item.fleetActionExecutedAt)}
                      </div>
                      <div
                        style={{
                          fontSize: "12px",
                          fontStyle: "italic",
                          color: theme.palette.neutralSecondaryAlt,
                        }}
                      >
                        {formatDateStringTimeOnly(item.fleetActionExecutedAt)}
                      </div>
                    </div>

                    <TooltipHost content={item.fleetActionReasonComment}>
                      <IconStyled iconName="Comment" />
                    </TooltipHost>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export const Table = styled.table`
  border-collapse: collapse;
  border-top: 1px solid ${(p) => p.theme.palette.neutralLighterAlt};
  width: 100%;
  td {
    padding: 5px 8px;
  }
  th {
    padding: 5px;
    font-size: 12px;
    font-weight: 650;
    border-bottom: 1px solid ${(p) => p.theme.palette.neutralLighterAlt};
    height: 42px;
    color: ${(p) => p.theme.palette.neutralPrimary};
  }
`;

const FleetActionCell = styled(Flex)`
  font-size: 12px;
  font-style: italic;
  color: ${(p) => p.theme.palette.neutralPrimary};
  text-align: center;
`;

const InsurerTypeCell = styled(Flex)`
  font-size: 12px;
  color: ${(p) => p.theme.palette.neutralPrimary};
  text-transform: uppercase;
  justify-content: "center";
  text-align: center;
  word-break: break-all;
`;

const PremiumTypeCell = styled(Flex)`
  font-size: 12px;
  color: ${(p) => p.theme.palette.neutralPrimary};
  text-transform: uppercase;
  justify-content: center;
  text-align: center;
`;

const PeriodCell = styled(Flex)`
  font-size: 12px;
  color: ${(p) => p.theme.palette.themePrimary};
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: italic;
  text-align: center;
`;

const Cell = styled(Flex)`
  font-size: 12px;
  color: ${(p) => p.theme.palette.neutralSecondary};
  text-align: center;
`;

const IconStyled = styled(Icon)`
  color: ${(p) => (p.theme.isDark ? "white" : "black")};
`;
