import { toast } from "components/FluentToast";
import { Formik } from "formik";
import { useCreateFleetVehicleDefaultSetting } from "hooks/data/mutations/useCreateFleetVehicleDefaultSetting";
import { useUpdateFleetVehicleDefaultSetting } from "hooks/data/mutations/useUpdateFleetVehicleDefaultSetting";
import { delay } from "library/delay";
import moment from "moment";
import useActionConfig from "pages/fleet-form/hooks/useActionConfg";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { loadFleetWithInsurersActions, setDialog } from "store/fleetForm";
import { useSelector } from "store/hooks";
import { createFieldPairValidation } from "store/util";
import { FleetHullCalculationDefaultSettingType } from "types/types";
import { setYupLocale } from "utils/setYupLocale";
import { formatDoubleFieldValue } from "utils/utils";
import * as yup from "yup";
import useDefaultSettingsData from "../hooks/useDefaultSettingsData";
import HullPremiumCalculationFormFields from "./HullPremiumCalculationFormFields";

type Props = {
  settingId: string;
  attributeName: string;
  isLoading: boolean;
};

const HullPremiumCalculationForm = ({
  settingId,
  attributeName,
  isLoading,
}: Props) => {
  const { t } = useTranslation();
  const fleet = useSelector((s) => s.fleetForm.fleetWithPremiums);
  const [isDirty, setIsDirty] = useState(false);
  const [isTradeLicensePlate, setTradeLicensePlate] = useState(false);

  const { mutate: onUpdateFleetVehicleDefaultSetting } =
    useUpdateFleetVehicleDefaultSetting();

  const { mutate: onCreateFleetVehicleDefaultSetting } =
    useCreateFleetVehicleDefaultSetting();

  const hasTradeLicensePlateDefault = useSelector(
    (s) => s.fleetForm.dialog.data?.hasTradeLicensePlate
  );

  const fleetAppSettings = useSelector((s) => s.fleetForm.fleetAppSettings);

  const actionsConfig = useActionConfig(fleetAppSettings);

  const defaultData = useDefaultSettingsData();

  const rowVersion = defaultData?.rowVersion;

  const dispatch = useDispatch();
  const vehicleType =
    defaultData?.settingCriteria?.vehicleTypeCodes &&
    defaultData?.settingCriteria?.vehicleTypeCodes.length > 0;

  useEffect(() => {
    if ((!vehicleType && settingId) || hasTradeLicensePlateDefault) {
      setTradeLicensePlate(true);
    }
  }, [vehicleType, settingId]);
  const close = () => {
    dispatch(setDialog({ type: "", isOpen: false }));
  };
  const premiumTypeOptions = actionsConfig.premiumTypeOptionsHull;

  const insurerOptions = actionsConfig.insurerOptions;
  const listPriceTypeOptions = actionsConfig.listPriceTypeOptions;
  //const vehicleMainGroupOptions = actionsConfig.vehicleMainGroupOptions;
  const vehicleTypeCodeOptions = actionsConfig.vehicleTypeCodeOptions;
  const carUsageOptions = actionsConfig.carUsageOptions;
  const numberOfSeatsRangeOptions = actionsConfig.numberOfSeatsRangeOptions;
  const numberOfSeatsMopedOptions = actionsConfig.numberOfSeatsMopedOptions;
  const cubicCapacityCcmOptions = actionsConfig.cubicCapacityCcmOptions;
  const weightCapacityKgOptions = actionsConfig.weightCapacityKgOptions;
  const maxGrossWeightKgOptions = actionsConfig.maxGrossWeightKgOptions;

  const initialValues = useMemo(() => {
    const hullValues = defaultData.attributeValue
      ? JSON.parse(defaultData.attributeValue)
      : null;
    return {
      validFromDate: defaultData?.validFromDate
        ? new Date(defaultData?.validFromDate)
        : new Date(),
      valueType: hullValues?.valueType
        ? hullValues?.valueType
        : "premiumRatePercentage",
      insurerGroupPolicyNumber: defaultData?.insurerGroupPolicyNumber
        ? {
            value: defaultData?.insurerGroupPolicyNumber,
            label: defaultData?.insurerGroupPolicyNumber,
          }
        : null,

      premiumRatePercentage:
        hullValues?.valueType &&
        hullValues?.valueType ===
          FleetHullCalculationDefaultSettingType.ABSOULUTE_PREMIUM_VALUE
          ? hullValues?.absoulutePremiumValue
          : hullValues?.premiumRatePercentage,
      premiumFreeSpecialEquipment: hullValues?.premiumFreeSpecialEquipment,
      gap: hullValues?.gap,
      replacementValue: hullValues?.replacementValue,
      grossNegligence: hullValues?.grossNegligence,
      electroPlusPackage: hullValues?.electroPlusPackage,
      discountForWaiverOfTaxRefund: hullValues?.discountForWaiverOfTaxRefund,
      isTradeLicensePlate: defaultData.settingCriteria
        ? defaultData.settingCriteria.isTradeLicensePlate
        : false,
      premiumType: defaultData
        ? premiumTypeOptions.find(
            (item) => item.value === defaultData.premiumType
          )
        : "",
      insurerPartnerNumber: defaultData
        ? insurerOptions.find(
            (item) => item.value === defaultData.insurerPartnerNumber
          )
        : "",
      listPriceType: defaultData
        ? listPriceTypeOptions.find(
            (item) =>
              item.value ===
              (defaultData.settingCriteria.listPrices &&
              defaultData.settingCriteria.listPrices.length > 0
                ? defaultData.settingCriteria.listPrices[0].type
                : 0)
          )
        : "",

      listPriceMin: defaultData
        ? defaultData.settingCriteria.listPrices &&
          defaultData.settingCriteria.listPrices.length > 0
          ? defaultData.settingCriteria.listPrices[0].min + ""
          : ""
        : "",
      listPriceMax: defaultData
        ? defaultData.settingCriteria.listPrices &&
          defaultData.settingCriteria.listPrices.length > 0
          ? defaultData.settingCriteria.listPrices[0].max + ""
          : ""
        : "",

      vehicleType: defaultData
        ? vehicleTypeCodeOptions.filter((item) =>
            defaultData.settingCriteria.vehicleTypeCodes?.includes(item.value)
          )
        : "",
      carUsage: defaultData
        ? carUsageOptions.filter((item) =>
            defaultData.settingCriteria.carUsageCodes?.includes(item.value)
          )
        : "",
      cubicCapacityCcmRange: defaultData
        ? cubicCapacityCcmOptions.filter((item) =>
            defaultData.settingCriteria.cubicCapacityCcmRangeCodes?.includes(
              item.value
            )
          )
        : "",
      powerKwFrom: defaultData
        ? defaultData.settingCriteria.powerKws &&
          defaultData.settingCriteria.powerKws.length > 0
          ? defaultData?.settingCriteria.powerKws[0].from
          : ""
        : "",
      powerKwTo: defaultData
        ? defaultData.settingCriteria.powerKws &&
          defaultData.settingCriteria.powerKws.length > 0
          ? defaultData?.settingCriteria.powerKws[0].to
          : ""
        : "",
      emptyWeightKgFrom: defaultData
        ? defaultData?.settingCriteria.emptyWeightKgs &&
          defaultData.settingCriteria.emptyWeightKgs.length > 0
          ? defaultData?.settingCriteria.emptyWeightKgs[0].from
          : ""
        : "",
      emptyWeightKgTo: defaultData
        ? defaultData.settingCriteria.emptyWeightKgs &&
          defaultData.settingCriteria.emptyWeightKgs.length > 0
          ? defaultData?.settingCriteria.emptyWeightKgs[0].to
          : ""
        : "",
      weightCapacityKgRange: defaultData
        ? weightCapacityKgOptions.filter((item) =>
            defaultData.settingCriteria.weightCapacityKgRangeCodes?.includes(
              item.value
            )
          )
        : "",
      maxGrossWeightKgRange: defaultData
        ? maxGrossWeightKgOptions.filter((item) =>
            defaultData.settingCriteria.maxGrossWeightKgRangeCodes?.includes(
              item.value
            )
          )
        : "",

      numberOfSeatsRange: defaultData
        ? numberOfSeatsRangeOptions.filter((item) =>
            defaultData.settingCriteria.numberOfSeatsRangeCodes?.includes(
              item.value
            )
          )
        : "",
      numberOfSeatsMoped: defaultData
        ? numberOfSeatsMopedOptions.filter((item) =>
            defaultData.settingCriteria.numberOfSeatsMopedCodes?.includes(
              item.value
            )
          )
        : "",
    };
  }, [
    defaultData,
    insurerOptions,
    premiumTypeOptions,
    vehicleTypeCodeOptions,
    listPriceTypeOptions,
    carUsageOptions,
    cubicCapacityCcmOptions,
    weightCapacityKgOptions,
    maxGrossWeightKgOptions,
    numberOfSeatsRangeOptions,
    numberOfSeatsMopedOptions,
  ]);

  const validationSchema = useMemo(() => {
    setYupLocale(t);
    return yup
      .object()
      .shape({
        validFromDate: yup.mixed().required(),
        valueType: yup.mixed().required(),
        premiumRatePercentage: yup.string().required(),
        premiumFreeSpecialEquipment: yup.string().required(),
        gap: yup.string().required(),
        replacementValue: yup.string().required(),
        grossNegligence: yup.string().required(),
        electroPlusPackage: yup.string().required(),
        discountForWaiverOfTaxRefund: yup.string().required(),

        premiumType: yup.mixed().required(),
        listPriceType: yup.mixed().required(),

        insurerPartnerNumber: yup.mixed().required(),
        //vehicleMainGroup: yup.mixed().required(),
        vehicleType: isTradeLicensePlate
          ? yup.array().nullable()
          : yup.array().of(yup.object()).min(1).required(),
        cubicCapacityCcmRange: yup.mixed().nullable(),

        weightCapacityKgRange: yup.mixed().nullable(),

        emptyWeightKgFrom: yup.number().nullable(),
        emptyWeightKgTo: yup.number().nullable(),
        powerKwFrom: yup.number().nullable(),
        powerKwTo: yup.number().nullable(),
      })
      .test(
        "emptyWeightKg",
        createFieldPairValidation("emptyWeightKgFrom", "emptyWeightKgTo")
      )
      .test("powerKw", createFieldPairValidation("powerKwFrom", "powerKwTo"))
      .test(
        "listPrice",
        createFieldPairValidation("listPriceMin", "listPriceMax")
      );
  }, [t, isTradeLicensePlate]);

  const onSubmit = (values) => {
    const localMoment = moment(values?.validFromDate);
    const localYear = localMoment.year();
    const localMonth = localMoment.month(); // Note: January is 0
    const localDate = localMoment.date();
    const localHours = localMoment.hours();
    const localMinutes = localMoment.minutes();
    const localSeconds = localMoment.seconds();
    const localMilliseconds = localMoment.milliseconds();

    const utcValidFromDate = new Date(
      Date.UTC(
        localYear,
        localMonth,
        localDate,
        localHours,
        localMinutes,
        localSeconds,
        localMilliseconds
      )
    );

    const body = {
      validFromDate: utcValidFromDate,
      insurerGroupPolicyNumber: values?.insurerGroupPolicyNumber?.value,
      isTradeLicensePlate: isTradeLicensePlate,
      attributeValue: JSON.stringify({
        valueType: values?.valueType.value,
        premiumRatePercentage:
          values?.valueType.value ===
          FleetHullCalculationDefaultSettingType.PREMIUM_RATE_PERCENTAGE
            ? values?.premiumRatePercentage
            : null,
        absoulutePremiumValue:
          values?.valueType.value ===
          FleetHullCalculationDefaultSettingType.ABSOULUTE_PREMIUM_VALUE
            ? values?.premiumRatePercentage
            : null,
        premiumFreeSpecialEquipment: values?.premiumFreeSpecialEquipment,
        gap: values?.gap,
        replacementValue: values?.replacementValue,
        grossNegligence: values?.grossNegligence,
        electroPlusPackage: values?.electroPlusPackage,
        discountForWaiverOfTaxRefund: values?.discountForWaiverOfTaxRefund,
      }),
      attributeName,
      premiumType: values?.premiumType.value,
      // listPriceType: values?.listPriceType.value,

      // listPriceMin: values?.listPriceMin
      //   ? Number(formatDoubleFieldValue(values?.listPriceMin))
      //   : undefined,
      // listPriceMax: values?.listPriceMax
      //   ? Number(formatDoubleFieldValue(values?.listPriceMax))
      //   : undefined,
      insurerPartnerNumber: values?.insurerPartnerNumber?.value,
      //vehicleMainGroup: values?.vehicleMainGroup?.value,
      // carUsage: values?.carUsage?.value,
      // vehicleType: isTradeLicensePlate ? null : values?.vehicleType?.value,
      // weightCapacityKgRange: values?.weightCapacityKgRange?.value,
      // maxGrossWeightKgRange: values?.maxGrossWeightKgRange?.value,
      // cubicCapacityCcmRange: values?.cubicCapacityCcmRange?.value,
      // powerKwFrom: values?.powerKwFrom,
      // powerKwTo: values?.powerKwTo,
      // numberOfSeatsRange: values?.numberOfSeatsRange?.value,
      // numberOfSeatsMoped: values?.numberOfSeatsMoped?.value,
      // emptyWeightKgFrom: values?.emptyWeightKgFrom,
      // emptyWeightKgTo: values?.emptyWeightKgTo,
      settingCriteria: {
        vehicleTypeCodes: isTradeLicensePlate
          ? null
          : values?.vehicleType.map((item) => item.value),

        carUsageCodes: values?.carUsage
          ? values?.carUsage.map((item) => item.value)
          : null,
        weightCapacityKgRangeCodes: values?.weightCapacityKgRange
          ? values?.weightCapacityKgRange?.map((item) => item.value)
          : null,
        maxGrossWeightKgRangeCodes: values?.maxGrossWeightKgRange
          ? values?.maxGrossWeightKgRange?.map((item) => item.value)
          : null,
        powerKws:
          values?.powerKwFrom && values?.powerKwTo
            ? [
                {
                  from: values?.powerKwFrom,
                  to: values?.powerKwTo,
                },
              ]
            : null,
        emptyWeightKgs:
          values?.emptyWeightKgFrom && values?.emptyWeightKgTo
            ? [
                {
                  from: values?.emptyWeightKgFrom,
                  to: values?.emptyWeightKgTo,
                },
              ]
            : null,
        cubicCapacityCcmRangeCodes: values?.cubicCapacityCcmRange
          ? values?.cubicCapacityCcmRange?.map((item) => item.value)
          : null,
        numberOfSeatsRangeCodes: values?.numberOfSeatsRange
          ? values?.numberOfSeatsRange?.map((item) => item.value)
          : null,
        numberOfSeatsMopedCodes: values?.numberOfSeatsMoped
          ? values?.numberOfSeatsMoped?.map((item) => item.value)
          : null,
        listPrices: values?.listPriceType
          ? [
              {
                type: values?.listPriceType?.value
                  ? values?.listPriceType?.value
                  : null,
                min: values?.listPriceMin
                  ? Number(formatDoubleFieldValue(values?.listPriceMin))
                  : undefined,
                max: values?.listPriceMax
                  ? Number(formatDoubleFieldValue(values?.listPriceMax))
                  : undefined,
              },
            ]
          : null,
        isTradeLicensePlate: isTradeLicensePlate,
      },
    };

    if (!!settingId) {
      onUpdateFleetVehicleDefaultSetting(
        {
          fleetId: fleet.fleetId,
          fleetDefaultSettingId: settingId,
          body: {
            fleetId: fleet.fleetId,
            ...body,
            rowVersion,
          },
        },
        {
          onSuccess: () => {
            toast.success(t("bfm.success"));
            close();
            dispatch(
              loadFleetWithInsurersActions.trigger({ fleetId: fleet.fleetId })
            );
          },
        }
      );
    } else {
      onCreateFleetVehicleDefaultSetting(
        {
          fleetId: fleet.fleetId,
          body: {
            fleetId: fleet.fleetId,
            ...body,
          },
        },
        {
          onSuccess: () => {
            toast.success(t("bfm.success"));
            close();
            dispatch(
              loadFleetWithInsurersActions.trigger({ fleetId: fleet.fleetId })
            );
          },
        }
      );
    }
  };

  const validate = (values) => {
    const errors = {} as any;
    if (values.valueType?.value !== "premiumRatePercentage") return;
    const premiumRatePercentageNumber = parseFloat(
      (values.premiumRatePercentage as string)
        ?.replaceAll(" ", "")
        .replaceAll(".", "")
        .replaceAll(",", ".")
    );
    if (premiumRatePercentageNumber) {
      if (premiumRatePercentageNumber > 100) {
        errors.premiumRatePercentage = t(
          "greco.form.errors.number.lessorequal",
          {
            max: 100,
          }
        );
      }
    }

    const gapNumber = parseFloat(
      (values.gap as string)
        ?.replaceAll(" ", "")
        .replaceAll(".", "")
        .replaceAll(",", ".")
    );
    if (gapNumber) {
      if (gapNumber > 100) {
        errors.gap = t("greco.form.errors.number.lessorequal", {
          max: 100,
        });
      }
    }

    const replacementValueNumber = parseFloat(
      (values.replacementValue as string)
        ?.replaceAll(" ", "")
        .replaceAll(".", "")
        .replaceAll(",", ".")
    );
    if (replacementValueNumber) {
      if (replacementValueNumber > 100) {
        errors.replacementValue = t("greco.form.errors.number.lessorequal", {
          max: 100,
        });
      }
    }

    const grossNegligenceNumber = parseFloat(
      (values.grossNegligence as string)
        ?.replaceAll(" ", "")
        .replaceAll(".", "")
        .replaceAll(",", ".")
    );
    if (grossNegligenceNumber) {
      if (grossNegligenceNumber > 100) {
        errors.grossNegligence = t("greco.form.errors.number.lessorequal", {
          max: 100,
        });
      }
    }

    const electroPlusPackageNumber = parseFloat(
      (values.electroPlusPackage as string)
        ?.replaceAll(" ", "")
        .replaceAll(".", "")
        .replaceAll(",", ".")
    );
    if (electroPlusPackageNumber) {
      if (electroPlusPackageNumber > 100) {
        errors.electroPlusPackage = t("greco.form.errors.number.lessorequal", {
          max: 100,
        });
      }
    }

    const discountForWaiverOfTaxRefundNumber = parseFloat(
      (values.discountForWaiverOfTaxRefund as string)
        ?.replaceAll(" ", "")
        .replaceAll(".", "")
        .replaceAll(",", ".")
    );
    if (discountForWaiverOfTaxRefundNumber) {
      if (discountForWaiverOfTaxRefundNumber > 100) {
        errors.discountForWaiverOfTaxRefund = t(
          "greco.form.errors.number.lessorequal",
          {
            max: 100,
          }
        );
      }
    }

    //...
    if (!values.listPriceType?.value) {
      errors.listPriceType = t("greco.required");
    }
    if (values.listPriceMin === "" && values.listPriceMax !== "") {
      errors.listPriceMin = t("greco.required");
    }
    if (values.listPriceMax === "" && values.listPriceMin !== "") {
      errors.listPriceMax = t("greco.required");
    }

    return errors;
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={validationSchema}
      validate={validate}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={async (values, { setSubmitting }) => {
        onSubmit(values);
        await delay(400);
        setSubmitting(false);
      }}
    >
      {(props) => {
        const { handleSubmit, initialValues } = props;
        return (
          <>
            <div>
              <form onSubmit={handleSubmit}>
                <HullPremiumCalculationFormFields
                  listPriceTypeOptions={listPriceTypeOptions}
                  isTradeLicensePlate={isTradeLicensePlate}
                  setTradeLicensePlate={setTradeLicensePlate}
                  settingId={settingId}
                  fieldProps={props}
                  defaultData={initialValues}
                  insurerOptions={insurerOptions}
                  //vehicleMainGroupOptions={vehicleMainGroupOptions}
                  carUsageOptions={carUsageOptions}
                  vehicleTypeCodeOptions={vehicleTypeCodeOptions}
                  isLoading={isLoading}
                  premiumTypeOptions={premiumTypeOptions}
                  setIsDirty={setIsDirty}
                />
              </form>
            </div>
          </>
        );
      }}
    </Formik>
  );
};

export default HullPremiumCalculationForm;
