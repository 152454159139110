import { DatePicker, Icon, Label } from "@fluentui/react";
import { FieldContainer } from "components/controls/FieldContainer";
import { useField } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";
import { VSpace } from "../Spacer";
import { ErrorMessage } from "../styled";
import { DateFieldConfig } from "./types";
import { useDynamicConfig } from "./useDynamicConfig";

type Props = { fieldConfig: DateFieldConfig };

const datePickerStyles = (theme, isMarked) => ({
  root: {
    ".ms-TextField-fieldGroup": {
      borderTop: "none !important",
      borderRight: "none !important",
      borderLeft: "none !important",
      background: "transparent !important",
      ...(isMarked && { background: theme.palette.yellowLight }),
    },
    ".ms-TextField-fieldGroup::after": {
      borderTop: "none !important",
      borderRight: "none !important",
      borderLeft: "none !important",
    },
    ".ms-TextField-fieldGroup::before": {
      display: "none",
    },
    ".ms-TextField-suffix": {
      //Cancel Icon
      marginRight: "30px",
      background: "none",
      cursor: "pointer",
    },
    ".ms-TextField-fieldGroup input": {
      background: "transparent !important",
      ...(isMarked && theme.isDark && { color: "black" }),
    },
  },
  icon: { right: "0" },
});

export const DateField = ({ fieldConfig }: Props) => {
  const config = {
    ...fieldConfig,
    ...useDynamicConfig(fieldConfig.d),
  };
  const {
    name,
    label,
    isVisible = true,
    isDisabled,
    isRequired,
    renderField,
    setFieldValue,
    errors,
    defaultValue,
    validate,
    values,
  } = config;
  const { t } = useTranslation();

  const theme = useTheme();
  const [field, { error }] = useField({ name, validate });

  const onFormatDate = (date?: Date): string => {
    return !date
      ? ""
      : ((date.getDate() + "").length === 1 ? "0" : "") +
          date.getDate() +
          "." +
          ((date.getMonth() + 1 + "").length === 1 ? "0" : "") +
          (date.getMonth() + 1) +
          "." +
          date.getFullYear();
  };
  const onParseDateFromString = useCallback(
    (newValue: string): Date => {
      const previousValue = values[name] || new Date();
      const newValueParts = (newValue || "").trim().split(".");
      const day =
        newValueParts.length > 0
          ? Math.max(1, Math.min(31, parseInt(newValueParts[0], 10)))
          : previousValue.getDate();
      const month =
        newValueParts.length > 1
          ? Math.max(1, Math.min(12, parseInt(newValueParts[1], 10))) - 1
          : previousValue.getMonth();
      let year =
        newValueParts.length > 2
          ? parseInt(newValueParts[2], 10)
          : previousValue.getFullYear();
      if (year < 100) {
        year += previousValue.getFullYear();
      }
      return new Date(year, month, day);
    },
    [values[name]]
  );

  useEffect(() => {
    setFieldValue(name, defaultValue);
  }, []);

  if (!isVisible) return null;
  const labelRender = (
    <Label required={isRequired} disabled={isDisabled}>
      {t(label)}
    </Label>
  );
  const fieldRender = (
    <DatePicker
      id={name}
      onSelectDate={(value) => {
        //setValue(value);
        setFieldValue(name, value);
      }}
      styles={datePickerStyles(theme, false)}
      textField={{
        name: name,
        required: isRequired,

        onRenderSuffix: () => (
          <Icon
            style={{
              visibility: values[name] && !isDisabled ? "visible" : "hidden",
            }}
            iconName="Cancel"
            onClick={() => {
              //setValue(null);
              setFieldValue(name, null);
            }}
          />
        ),
        styles: { suffix: { padding: "0 4px" } },
      }}
      formatDate={onFormatDate}
      disabled={isDisabled}
      value={values[name]}
      allowTextInput={true}
      disableAutoFocus={true}
      parseDateFromString={onParseDateFromString}
    />
  );

  const errorRender = errors && errors[name] && (
    <>
      <ErrorMessage id={field.name + "_error"}>{errors[name]}</ErrorMessage>
    </>
  );

  return (
    <div
      style={{
        flex: 1,
      }}
    >
      <FieldContainer
        isTooltipHidden={isDisabled}
        tooltipText={label}
        isReadOnly={true}
      >
        {labelRender}
        {renderField ? renderField({ field: fieldRender }) : fieldRender}
        {errorRender}
      </FieldContainer>
      <VSpace height={10} />
    </div>
  );
};

export default React.memo(DateField);
