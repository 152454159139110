import { useMutation } from "react-query";
import { CancelFleetPremiumParams, continueFleetPremium } from "store/api/api";
import { handleAxiosError } from "store/util";
import { MUTATION_KEYS } from "../queryKeys";

const continueFleetPremiumFn = async (params: CancelFleetPremiumParams) => {
  return await continueFleetPremium(params);
};

export function useContinueFleetPremium() {
  return useMutation(continueFleetPremiumFn, {
    mutationKey: MUTATION_KEYS.continueFleetPremium,

    onError: (err) => {
      handleAxiosError(err);
    },
  });
}
